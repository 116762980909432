<template>
  <div>
    <b-modal
      ref="modal-custo-mensagens"
      id="modal-custo-mensagens-id"
      centered
      title="BootstrapVue"
      cancel-disabled
      hide-footer
      hide-header
      body-class="p-0 m-0"
      ok-disabled
      tbody-tr-class="text-center"
      class=""
    >
      <template #modal-header="{}"> </template>

      <template #default="{ hide, close }">
        <div
          class="d-flex align-items-center justify-content-between mb-2 px-4 py-5"
        >
          <h4 style="width: 250px">Custo por Mensagens Whatsapp Oficial</h4>
          <b-button size="sm" variant="outline-secondary">{{
            textoPeriodo
          }}</b-button>
        </div>

        <div
          v-for="(categoria, key) in custosFormatados"
          :key="key"
          style="margin-bottom: 30px"
        >
          <div
            class="px-4 d-flex align-items-center font-weight-bold"
            style="background-color: #cdcdcf; height: 40px"
          >
            {{ traducaoLabel(key) }}
          </div>
          <b-table
            sort-by="start"
            sort-desc
            sticky-header
            responsive
            hover
            :items="categoria"
            :fields="fields"
          />
          <div
            style="
              background-color: #edeef3;
              position: relative;
              top: -13px;
              height: 40px;
            "
            class="px-5 d-flex align-items-center justify-content-end"
          >
            <div style="width: 33%">
              <b>{{ `Total ${traducaoLabel(key)}` }}</b>
            </div>
            <div class="d-flex justify-content-center" style="width: 40%">
              <b>
                {{
                  `${categoria.reduce(
                    (acc, curr) => acc + parseFloat(curr.conversation),
                    0
                  )}`
                }}
              </b>
            </div>
            <div class="d-flex justify-content-end" style="width: 26%">
              <b>
                {{
                  ` $ ${categoria
                    .reduce((acc, curr) => acc + parseFloat(curr.cost), 0)
                    .toFixed(3)}`.replace(".", ",")
                }}
              </b>
            </div>
          </div>
        </div>
        <div
          style="position: relative; height: 40px; background-color: #edeef3"
          class="d-flex align-items-center justify-content-end"
        >
          <div style="width: 33%" class="pl-4">
            <b>TOTAL GERAL</b>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            style="width: 40%"
          >
            <b>
              {{
                custos[0].data_points.reduce(
                  (acc, curr) => acc + parseFloat(curr.conversation),
                  0
                )
              }}
            </b>
          </div>
          <div
            class="d-flex justify-content-end align-items-center pr-5"
            style="width: 26%"
          >
            <b>
              {{
                `$ ${custos[0].data_points
                  .reduce((acc, curr) => acc + parseFloat(curr.cost), 0)
                  .toFixed(3)} `.replace(".", ",")
              }}
            </b>
          </div>
        </div>
        <div class="text-right" style="padding: 2rem 2rem">
          <a class="btn btn-sm btn-light-primary mr-1" @click="close()">
            Fechar
          </a>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    custos: {
      type: Array,
      default: () => []
    },
    textoPeriodo: {
      type: String,
      default: "Mês Atual"
    }
  },
  data() {
    return {
      fields: [
        {
          key: "start",
          sortable: true,
          label: "Data",
          formatter: (value) => {
            return moment(value, "X").format("L");
          }
        },
        {
          key: "conversation",
          sortable: true,
          label: "Quantidade",
          class: "text-center"
        },
        {
          key: "cost",
          label: "Custo",
          sortable: true,
          class: "text-right",
          formatter: (value) => {
            return `$ ${value}`.replace(".", ",");
          }
        }
      ]
    };
  },
  methods: {
    fechar() {
      this.$refs["modal-custo-mensagens"].hide();
    },
    abrir() {
      this.$refs["modal-custo-mensagens"].show();
    },
    traducaoLabel(key) {
      const labels = {
        UTILITY: "Utilidade",
        SERVICE: "Serviço",
        MARKETING: "Marketing"
      };
      return labels[key] || key;
    }
  },
  computed: {
    custosFormatados() {
      return Object.values(this.custos[0].data_points).reduce((acc, curr) => {
        let key = curr.conversation_category;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});
    }
  }
};
</script>

<style scoped>
h1 {
  color: blue;
}

p {
  font-size: 18px;
}
</style>
